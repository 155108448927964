type handlerFn = (arg?: any) => void

export class EventEmmiter<T extends string> {
  protected _eventHandlers: Partial<Record<T, handlerFn[]>> = {};

  public on(type: T, handler: handlerFn) {
    this._eventHandlers[type] = [...(this._eventHandlers[type] ?? []), handler];
    return this;
  }

  protected emit(type: T, data?: any) {
    const handlers = this._eventHandlers[type];
    if (handlers) {
      handlers.forEach(fn => fn(data));
    }
  }
}
