export namespace LabWebsocket {
  export enum ACTIONS {
    CONNECT_LAB = 'connect_lab',
    START_SESSION = 'start_interactive_session',
    SESSION = 'interactive_session',
    FINISH = 'finish_lab',
    FINISH_TASK = 'finish_task',
    RESIZE = 'resize',
    EVENT = 'lab_event',
    ERROR = 'lab_error',
    TESTING = 'testing_lab',
    OTHER_LAB = 'other_lab_connected'
  }

  export enum FINISH_MESSAGES {
    CALC_STARTED = 'lab score calculation started',
    CALC_FINISHED = 'lab score calculation finished',
    FINISHED = 'lab env terminated'
  }

  export namespace IncomingMessages {
    export type Message =
      | ErrorMessage
      | ConnectLab
      | StartSession
      | Session
      | Finish
      | FinishTask
      | EventLog
      | ErrorLog
      | Testing
      | OtherLab;

    export interface ErrorMessage {
      action: ACTIONS;
      error: string;
    }

    export interface ConnectLab {
      action: ACTIONS.CONNECT_LAB;
      message: string;
      ip: string[];
      ports: Record<string, string>;
    }

    export interface StartSession {
      action: ACTIONS.START_SESSION;
      message: string;
      ip: string[];
    }

    export type Session = Blob;

    export type Finish = {
      action: FINISH_MESSAGES.CALC_FINISHED;
      labID?: number;
      otherLabID?: number;
      logID?: number;
      taskID?: string;
      error: string | null;
    };

    export type WrongTask = {
      taskID: string;
      output: Array<string>;
    };

    export type FinishTask = {
      action: ACTIONS.FINISH_TASK;
      labID?: number;
      logID?: number;
      userID?: number;
      taskID?: string;
      wrongTasks: Array<WrongTask>;
      score?: number;
      error: string | null;
      taskSuccess: boolean;
    };

    export interface EventLog {
      action: ACTIONS.EVENT;
      message: string;
    }

    export interface ErrorLog {
      action: ACTIONS.ERROR;
      error: string;
    }

    export interface Testing {
      action: ACTIONS.TESTING;
      message: string;
    }

    export interface OtherLab {
      action: ACTIONS.OTHER_LAB;
      labID: number;
      otherLabId: number;
      otherLabName: string;
    }
  }

  export namespace OutgoingMessages {
    export type Message =
      | ConnectLab
      | StartSession
      | Session
      | Finish
      | FinishTask
      | Resize;

    export interface ConnectLab {
      action: ACTIONS.CONNECT_LAB;
      cols: number;
      rows: number;
    }

    export interface StartSession {
      action: ACTIONS.START_SESSION;
    }

    export interface Session {
      action: ACTIONS.SESSION;
      message: string;
    }

    export interface Finish {
      action: ACTIONS.FINISH;
      logID?: number;
      taskID?: string;
      otherLabID?: number;
    }

    export interface FinishTask {
      action: ACTIONS.FINISH_TASK;
      taskID: string;
      currentTaskId: string;
      logID?: number;
    }

    export interface Resize {
      action: ACTIONS.RESIZE;
      cols: number;
      rows: number;
    }
  }
}
