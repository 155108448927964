









import { Component, Vue } from 'vue-property-decorator';
import TopBar from '@/components/layout/topBar/Index.vue';

@Component({
  name: 'UserLayout',
  components: {
    TopBar
  }
})
export default class extends Vue {
  get key() {
    return this.$route.path;
  }
}
