


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {IUserCourseInfo} from '../../models/users';
import ProgressBar from '@/components/progressBar/Index.vue';
import {DialogModule} from '@/store/modules/dialogState';
import {UserDashboardModule} from '@/store/modules/userDashboardState';

@Component({
  name: 'CourseCard',
  components: {
    ProgressBar,
  },
})
export default class extends Vue {
  @Prop() private course!: IUserCourseInfo;

  openCourse(courseID: number) {
    this.$router.push({
      name: 'CourseView',
      params: {id: courseID.toString()},
    });
  }
}
