



































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

import { IStep } from '@/models/users';
import ProgressBar from '@/components/progressBar/Index.vue';
import Timer from '@/components/timer/index.vue';
import { UserLabModule } from '@/store/modules/userLabState';
import VueMarkdown from 'vue-markdown-render';
import Hint from '@/assets/svg/Hint.vue';
import Camera from '@/assets/svg/Camera.vue';
import CollapsePaginationArrow from '@/assets/svg/CollapsePaginationArrow.vue';
import Failed from '@/assets/svg/Failed.vue';

@Component({
  name: 'LabChallenges',
  components: {
    ProgressBar,
    Timer,
    VueMarkdown,
    Hint,
    Camera,
    Failed,
    CollapsePaginationArrow
  }
})
export default class extends Vue {
  private step: IStep | null = null;
  private currentPage = 0;
  private isVisibleHint = false;
  private isCollapsePagination = false;
  private isRotateArrow = false;
  private maxPage = 0;
  private maxTaskID;
  private waitIndex = -1;
  private showVideoDialog = false;
  private videoLoading = false;
  private playerError = false;
  private waitFinishTaskData: any = {};
  private failedTasks: any = {};
  private isLastTaskTested = false;
  private nextSelectedPage = 0;

  @Prop()
  private connectionReady;
  @Prop()
  private challenge;
  @Prop()
  private lab;
  @Prop()
  private taskID;
  @Prop()
  private isInputTerminal;
  @Prop()
  private isStartSession;
  @Ref()
  private markedHint;

  get labDisabled() {
    return UserLabModule.labDisabled;
  }

  get labFinished() {
    return UserLabModule.labFinished;
  }

  get logID() {
    return UserLabModule.lab?.logID;
  }

  get timeRemained() {
    return (!this.labFinished && this.lab && this.lab.remained) || false;
  }

  get buttonText() {
    return this.isVisibleHint ? 'Hide hint' : 'Show hint';
  }

  get scrollHeightHint() {
    return this.$refs.hint.scrollHeight;
  }

  get scrollHeightPagination() {
    return this.$refs.pagination.scrollHeight;
  }

  get isLastTask() {
    return this.challenge?.questions.length === this.currentPage + 1;
  }

  $refs!: {
    hint: HTMLElement;
    pagination: HTMLElement;
    paginationItem: HTMLElement[];
  };

  @Watch('isCollapsePagination')
  isCollapsePaginationChanges() {
    this.isCollapsePagination
      ? (this.$refs.pagination.style.maxHeight = 34 + 'px')
      : (this.$refs.pagination.style.maxHeight =
          this.scrollHeightPagination + 'px');
  }

  mounted() {
    this.changePagination(0);
    this.$refs.pagination.style.maxHeight = this.scrollHeightPagination + 'px';
    this.changeMaxPage();

    if (this.taskID) {
      this.challenge.questions.find((item, index) => {
        if (this.taskID == item.taskID) {
          this.maxPage = index;

          this.setNextPage(index);
          this.changePagination(index);

          return true;
        }

        return false;
      });
    }

    this.step = this.challenge?.questions[this.maxPage || 0] ?? null;
    this.maxTaskID = this.step?.taskID;
  }

  collapsePagination() {
    this.isCollapsePagination = !this.isCollapsePagination;
    this.isRotateArrow = !this.isRotateArrow;
  }

  isDisablePage(idx: number): boolean {
    if (this.challenge.questions[this.currentPage].waitSuccess) {
      return idx > this.currentPage;
    } else {
      const findWait = this.challenge.questions.findIndex(
        (el, j) => j > this.currentPage && el.waitSuccess
      );
      return findWait !== -1 && idx > findWait;
    }
  }

  changePagination(page: number) {
    this.nextSelectedPage = page;

    if (this.challenge && !this.challenge.questions) {
      this.challenge.questions = [];
    }

    const curStep = this.step;
    this.isVisibleHint = false;

    if (page <= this.maxPage && !this.maxTaskID) {
      this.maxTaskID = curStep?.taskID;
    }

    this.waitFinishTaskData.page = page;
    this.waitFinishTaskData.taskID = this.challenge?.questions[page]?.taskID;
    this.sendWaitTask();

    UserLabModule.FinishTask({
      taskID: this.step?.taskID as string,
      nextTaskID: this.challenge?.questions[page]?.taskID || '',
      logID: this.logID
    });
  }

  finishTask() {
    this.sendWaitTask();
    UserLabModule.FinishTask({
      taskID: this.step?.taskID as string,
      nextTaskID: this.challenge?.questions[this.currentPage]?.taskID || '',
      logID: this.logID
    });
  }

  private setWaitingData = () => {
    const page = this.waitFinishTaskData?.page || 0;

    this.step = this.challenge?.questions[page] ?? null;

    if (page > this.maxPage) {
      this.maxPage = page;
      this.maxTaskID = this.waitFinishTaskData?.taskID;
    }

    this.setNextPage(page);
  };

  setNextPage(page: number) {
    this.currentPage = page;
  }

  @Watch('currentPage')
  changeCurrentPage() {
    this.step = this.challenge?.questions[this.currentPage] ?? null;
  }

  @Watch('isInputTerminal')
  onInputTerminal() {
    if (this.isLastTask) this.isLastTaskTested = false;
  }

  timeLeftHandler() {
    UserLabModule.FinishLab('', 'Time to challenge left');
  }

  finishHandler() {
    UserLabModule.SetLoading();
    UserLabModule.FinishLab(this.step?.taskID as string);
  }

  focus(event) {
    const el = this.markedHint?.$el || this.markedHint;
    if (event == 'hint') {
      if (el) {
        setTimeout(() => {
          const t = el.offsetTop + el.scrollHeight + 10;
          el.parentElement.scrollTop = t;
          el.focus();
        }, 301);
      }
    }
  }

  @Watch('maxPage')
  changeMaxPage() {
    this.waitIndex = -1;
    this.challenge.questions.find((item, index) => {
      const nextWait = index >= this.maxPage && item.waitSuccess;
      if (nextWait) {
        this.waitIndex = index + 1;
      }
      return nextWait;
    });
  }

  sendWaitTask() {
    this.$emit('waitTask');
  }

  closeVideoDialog() {
    this.showVideoDialog = false;
    this.playerError = true;
  }

  openVideoDialog() {
    if (!this.lab?.videoUrl) {
      return;
    }
    this.playerError = false;
    this.showVideoDialog = true;
    this.videoLoading = true;
  }

  public onFinishTask(labID, taskID, score, failed, taskSuccess) {
    this.failedTasks = {};
    if (failed && failed.length) {
      const showArray = {};
      this.challenge.questions.findIndex(item => {
        showArray[item.taskID] = true;
        return item.taskID == this.maxTaskID;
      });

      failed.forEach(item => {
        this.failedTasks[item.taskID.slice(5)] = item.output;
      });
    }

    this.challenge.questions.findIndex((item, index) => {
      if (item.waitSuccess && this.failedTasks[item.taskID]) {
        this.maxPage = index;
        return true;
      }
    });

    if (this.challenge.questions[this.currentPage].waitSuccess && taskSuccess) {
      this.setWaitingData();
    }
    if (!this.challenge.questions[this.currentPage].waitSuccess) {
      this.setWaitingData();
    }
    if (this.nextSelectedPage < this.currentPage) {
      this.setWaitingData();
    }

    const isLastTask =
      taskID ===
      this.challenge.questions[this.challenge.questions.length - 1].taskID;

    if (isLastTask) {
      if (taskSuccess && Object.keys(this.failedTasks).length === 0) {
        this.finishHandler();
      } else {
        this.isLastTaskTested = true;
      }
    }
  }
}
