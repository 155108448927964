











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Task from '@/assets/svg/Task.vue';
import Done from '@/assets/svg/Done.vue';
import Failed from '@/assets/svg/Failed.vue';
import Trophy from '@/assets/svg/Trophy.vue';
import CollapseArrow from '@/assets/svg/CollapseArrow.vue';
import ColoredProgress from '@/components/colored-progress/Index.vue';
import VueMarkdown from 'vue-markdown-render';

@Component({
  name: 'CollapseList',
  components: {
    CollapseArrow,
    Done,
    Failed,
    Trophy,
    Task,
    ColoredProgress,
    VueMarkdown
  },
  data: () => {
    return {
      activeTask: ''
    };
  },
  filters: {
    fistLine: function(value) {
      if (!value) return '';
      value = value.toString();

      return value.split('\n').shift();
    }
  }
})
export default class extends Vue {
  @Prop() resultData;

  rotateCollapseArrow(index) {
    this.$refs.title[index].lastElementChild.classList.toggle('rotate');
  }
}
