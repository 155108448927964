import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators';
import store from '@/store';
import { IChallenge, ILabInfo } from '@/models/users';
import { ILabResult } from '@/models/labs';
import { getChallengeByID, getLabStateById } from '@/api/labs';
import { LabWebsocketConnection } from '@/utils/LabWebsocket';
import { LabWebsocket } from '@/models/labWs';

@Module({ dynamic: true, store, name: 'userLab' })
class UserLab extends VuexModule {
  ws: LabWebsocketConnection | null = null;

  challenge: IChallenge | null = null;
  lab: ILabInfo | null = null;
  labResult: ILabResult | null = null;

  answ: { text: string } = { text: '' };

  infoLoading = true;
  connectionError = '';
  connectionLoading = true;
  labDisabled = false;
  labFinished = false;
  termSize: any = {
    cols: 80,
    rows: 40
  };
  private labEventCallback;
  private labErrorCallback;
  private finishLabCallback;
  private finishTaskCallback;
  private labTestingCallback;
  private labDisableCallback;
  private otherLabCallback;

  @Mutation
  private SET_CHALLENGE(challenge: IChallenge | null) {
    this.challenge = challenge;
  }

  @Mutation
  private SET_LOADING(val) {
    this.infoLoading = val;
  }

  @Mutation
  private SET_LAB(lab) {
    this.lab = lab;
  }

  @Mutation
  private SET_LOG_ID(logID) {
    if (this.lab) this.lab.logID = logID;
  }

  @Mutation
  private SET_LAB_ADRESSES(ip) {
    if (!this.lab) {
      return;
    }
    this.lab.ip = ip ? ip : undefined;
  }

  @Mutation
  private ANSW(answ) {
    this.answ = answ;
  }

  @Mutation
  private SET_CONNECT_LOADING(value: boolean) {
    this.connectionLoading = value;
  }

  @Mutation
  private SET_DISABLED(value: boolean) {
    this.labDisabled = value;
  }

  @Mutation
  private SET_FINISHED(status: boolean) {
    this.labFinished = status;
  }

  @Mutation
  private SET_LAB_RESULT(result: any) {
    this.labResult = result;
  }

  @Action
  private SetDisabled(value: boolean) {
    this.SET_DISABLED(value);
    if (this.labDisableCallback) {
      this.labDisableCallback(value);
    }
  }

  @Mutation
  private SET_WS(ws: LabWebsocketConnection | null) {
    this.ws = ws;
  }

  @Mutation
  private SET_ERROR(val) {
    this.connectionError = val;
  }

  @Mutation
  private SET_TERM_SIZE(termSize) {
    if (!this.termSize) this.termSize = {};

    this.termSize.cols = termSize?.cols;
    this.termSize.rows = termSize?.rows;
  }

  @Action
  public SetTermSize(termSize) {
    this.SET_TERM_SIZE(termSize);
  }

  @Action
  public SetAnsw(msg) {
    const newText = { text: msg.text.trim() };
    this.ANSW(newText);
  }

  @Action({ rawError: true })
  public async GetChallenge(id) {
    try {
      const response = await getChallengeByID(id);
      this.SET_CHALLENGE(response.data ? response.data : null);
    } catch (e) {
      console.error(e);
      this.SET_CHALLENGE(null);
    }
  }
  @Action
  public async GetLabById(id) {
    try {
      const response = await getLabStateById(id);
      this.SET_LAB(response.data);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public async SetLab(lab) {
    this.SET_LAB(lab);
  }

  @Action
  public async SetLabResult(labResult) {
    this.SET_LAB_RESULT(labResult);
  }

  @Action
  public async InitLabWebsocket(id: number) {
    this.SetDisabled(true);
    try {
      const ws = await LabWebsocketConnection.getInstance(id, {
        [LabWebsocket.ACTIONS.CONNECT_LAB]: [
          this.errorMessageHandler,
          this.connectLabHandler
        ],
        [LabWebsocket.ACTIONS.SESSION]: [
          this.errorMessageHandler,
          () => {
            this.SET_CONNECT_LOADING(false);
            // this.labEvent(LabWebsocket.ACTIONS.START_SESSION);
          }
        ],
        [LabWebsocket.ACTIONS.START_SESSION]: [
          this.errorMessageHandler,
          ev => {
            this.labEvent(LabWebsocket.ACTIONS.START_SESSION);
            this.SetFinishLoading();
            this.SET_LAB_ADRESSES(ev.ip);
          }
        ],
        [LabWebsocket.ACTIONS.FINISH]: [
          this.errorFinishHandler,
          this.finishLabHandler
        ],
        [LabWebsocket.ACTIONS.FINISH_TASK]: [
          this.errorFinishHandler,
          this.finishTaskHandler
        ],
        [LabWebsocket.ACTIONS.EVENT]: [
          this.errorMessageHandler,
          this.catchLabLogs
        ],
        [LabWebsocket.ACTIONS.ERROR]: [this.catchLabErrors],
        [LabWebsocket.ACTIONS.TESTING]: [
          this.errorMessageHandler,
          this.onTesting
        ],
        [LabWebsocket.ACTIONS.OTHER_LAB]: [
          this.errorMessageHandler,
          this.onOtherLabConnecting
        ]
      });
      this.SET_WS(ws);
    } catch (e) {
      console.error(e);
      this.SET_ERROR('Error while connecting to remote terminal');
    }
  }

  @Action
  public async Init(id: number) {
    await Promise.all([this.GetChallenge(id), this.GetLabById(id)])
      .catch(e => {
        throw e;
      })
      .finally(() => {
        this.SET_LOADING(false);
      });
    if (this.challenge?.totalTimeEstimated) {
      this.InitLabWebsocket(id);
    } else {
      this.SET_CONNECT_LOADING(false);
      this.SET_ERROR('Remaining time left');
    }
  }

  @Action
  public async ClearState() {
    this.ws?.destroy();
    this.SET_WS(null);
    this.SET_CONNECT_LOADING(true);
    this.SetDisabled(false);
    this.SET_LOADING(true);
    this.SET_ERROR('');
    this.SET_CHALLENGE(null);
    this.SET_LAB(null);
  }

  @Action
  public FinishLab(taskID: string, message?: string) {
    if (message) this.SET_ERROR(message);
    this.ws?.FinishLab(taskID, this.lab?.logID as number);
  }

  @Action
  public FinishLabByID(labID: number) {
    this.ws?.FinishLab('', 0, labID);
  }

  @Action
  public FinishTask({
    nextTaskID,
    taskID,
    logID
  }: {
    nextTaskID: string;
    taskID: string;
    logID?: number;
  }) {
    this.ws?.FinishTask(taskID, nextTaskID, logID || this.lab?.logID);
    this.SetDisabled(true);
    this.SET_CONNECT_LOADING(true);
  }

  @Action
  public async SetLogID(logID: number) {
    this.SET_LOG_ID(logID);
  }

  /**
   * WS Handlers
   */

  @Action
  private connectLabHandler(msg: LabWebsocket.IncomingMessages.ConnectLab) {
    this.SET_LAB_ADRESSES(null);
    this.SET_FINISHED(false);
    if (msg.message.includes('session')) {
      // HACK: sending empty message to get start symbol from terminal
      //   this.ws?.InteractiveSession('');
    }
    this.labEvent(msg.action);
  }

  @Action
  private errorMessageHandler(e: LabWebsocket.IncomingMessages.ErrorMessage) {
    this.SET_CONNECT_LOADING(false);
    this.labError(e.error || 'Unknown error');
  }

  @Action
  private errorFinishHandler(e: LabWebsocket.IncomingMessages.ErrorMessage) {
    this.SET_CONNECT_LOADING(false);
    if (this.finishLabCallback) {
      this.finishLabCallback(null, e.error);
    } else {
      this.labError(e.error || 'Unknown error');
    }
  }

  @Action
  private finishLabHandler(msg: LabWebsocket.IncomingMessages.Finish) {
    const { labID, otherLabID, logID, error } = msg;

    this.ws?.destroy();
    // this.labEvent(msg.action);

    this.SET_CONNECT_LOADING(false);
    this.SetDisabled(true);

    if (
      this.finishLabCallback &&
      (otherLabID === undefined || otherLabID < 1)
    ) {
      this.finishLabCallback(labID, logID, error);
    }
  }

  @Action
  private finishTaskHandler(msg: LabWebsocket.IncomingMessages.FinishTask) {
    const { labID, taskID, logID, score, wrongTasks, error, taskSuccess } = msg;
    this.SET_CONNECT_LOADING(false);
    this.SetDisabled(false);

    if (this.finishTaskCallback) {
      this.finishTaskCallback(
        labID,
        taskID,
        logID,
        Number(score),
        wrongTasks,
        error,
        taskSuccess
      );
    }
  }

  @Action
  public SetLabEventCallback(cb) {
    this.labEventCallback = cb;
  }

  @Action
  public SetLabErrorCallback(cb) {
    this.labErrorCallback = cb;
  }

  @Action
  public SetLabTestingCallback(cb) {
    this.labTestingCallback = cb;
  }

  @Action
  public SetFinishLabCallback(cb) {
    this.finishLabCallback = cb;
  }

  @Action
  public SetFinishTaskCallback(cb) {
    this.finishTaskCallback = cb;
  }

  @Action
  public SetLabDisableCallback(cb) {
    this.labDisableCallback = cb;
  }

  @Action
  public SetOtherLabConnectedCallback(cb) {
    this.otherLabCallback = cb;
  }

  @Action
  private labEvent(action) {
    if (this.labEventCallback) this.labEventCallback(action);
  }

  @Action
  private catchLabLogs(msg) {
    this.labEvent('\r[inf]: ' + msg.message);
  }

  @Action
  private catchLabErrors(msg) {
    if (this.labErrorCallback) this.labErrorCallback(msg.error);
  }

  @Action
  private labError(action) {
    if (this.labErrorCallback) this.labErrorCallback(action);
  }

  @Action
  public SetLoading() {
    this.SET_CONNECT_LOADING(true);
    this.SetDisabled(true);
  }

  @Action
  public SetDisconnect() {
    this.SetDisabled(true);
  }

  @Action
  public SetFinishLoading() {
    this.SET_CONNECT_LOADING(false);
    this.SetDisabled(false);
  }

  @Action
  public onTesting() {
    this.SET_FINISHED(true);
    if (this.labTestingCallback) this.labTestingCallback();
  }

  @Action
  public onOtherLabConnecting(event) {
    if (this.otherLabCallback && event) {
      const { labID, otherLabID, otherLabName } = event;
      this.otherLabCallback(labID, otherLabID, otherLabName);
    }
  }
}

export const UserLabModule = getModule(UserLab);
