export class ReconnectingWebSocket {
  //These can be altered by calling code
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public debug: boolean = false;

  //Time to wait before attempting reconnect (after close)
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public reconnectInterval: number = 1000;
  //Time to wait for WebSocket to open (before aborting and retrying)
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public timeoutInterval: number = 2000;

  //Should only be used to read WebSocket readyState
  public readyState: number;

  //Whether WebSocket was forced to close by this client
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  private forcedClose: boolean = false;
  //Whether WebSocket opening timed out
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  private timedOut: boolean = false;

  //List of WebSocket sub-protocols
  private protocols: string[] = [];

  //The underlying WebSocket
  // eslint-disable-next-line
  private ws!: WebSocket;
  private url: string;

  /**
   * Setting this to true is the equivalent of setting all instances of ReconnectingWebSocket.debug to true.
   */
  public static debugAll = false;

  //Set up the default 'noop' event handlers
  public onopen: (ev: Event) => void = function(event: Event) {
    /**/
  };
  public onclose: (ev: CloseEvent) => void = function(event: CloseEvent) {
    /**/
  };
  public onconnecting: () => void = function() {
    /**/
  };
  public onmessage: (ev: MessageEvent) => void = function(event: MessageEvent) {
    /**/
  };
  public onerror: (ev: ErrorEvent) => void = function(event: ErrorEvent) {
    /**/
  };

  constructor(url: string, protocols: string[] = []) {
    this.url = url;
    this.protocols = protocols;
    this.readyState = WebSocket.CONNECTING;
    this.connect(false);
  }

  public connect(reconnectAttempt: boolean) {
    this.ws = new WebSocket(this.url, this.protocols);

    this.onconnecting();
    this.log('ReconnectingWebSocket', 'attempt-connect', this.url);

    const localWs = this.ws;
    const timeout = setTimeout(() => {
      this.log('ReconnectingWebSocket', 'connection-timeout', this.url);
      this.timedOut = true;
      localWs.close();
      this.timedOut = false;
    }, this.timeoutInterval);

    this.ws.onopen = (event: Event) => {
      clearTimeout(timeout);
      this.log('ReconnectingWebSocket', 'onopen', this.url);
      this.readyState = WebSocket.OPEN;
      reconnectAttempt = false;
      this.onopen(event);
    };

    this.ws.onclose = (event: CloseEvent) => {
      clearTimeout(timeout);
      delete this.ws;
      if (this.forcedClose || event.code === 1000) {
        this.readyState = WebSocket.CLOSED;
        this.onclose(event);
      } else {
        this.readyState = WebSocket.CONNECTING;
        this.onconnecting();
        if (!reconnectAttempt && !this.timedOut) {
          this.log('ReconnectingWebSocket', 'onclose', this.url);
          this.onclose(event);
        }
        setTimeout(() => {
          this.connect(true);
        }, this.reconnectInterval);
      }
    };
    this.ws.onmessage = (event) => {
      this.log('ReconnectingWebSocket', 'onmessage', this.url, event.data);
      this.onmessage(event);
    };
    this.ws.onerror = (event) => {
      this.log('ReconnectingWebSocket', 'onerror', this.url, event);
      this.onerror(event as ErrorEvent);
    };
  }

  public send(data: any) {
    if (this.ws) {
      this.log('ReconnectingWebSocket', 'send', this.url, data);
      return this.ws.send(data);
    } else {
      throw 'INVALID_STATE_ERR : Pausing to reconnect websocket';
    }
  }

  /**
   * Returns boolean, whether websocket was FORCEFULLY closed.
   */
  public close(): boolean {
    if (this.ws) {
      this.forcedClose = true;
      this.ws.close();
      return true;
    }
    return false;
  }

  /**
   * Additional public API method to refresh the connection if still open (close, re-open).
   * For example, if the app suspects bad data / missed heart beats, it can try to refresh.
   *
   * Returns boolean, whether websocket was closed.
   */
  public refresh(): boolean {
    if (this.ws) {
      this.ws.close();
      return true;
    }
    return false;
  }

  private log(...args: any[]) {
    if (this.debug || ReconnectingWebSocket.debugAll) {
      console.debug(...args);
    }
  }

  public addEventListener(event, cb) {
    return this.ws && this.ws.addEventListener(event, cb);
  }

  public removeEventListener(event, cb) {
    return this.ws && this.ws.removeEventListener(event, cb);
  }
}
