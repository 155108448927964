<template>
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5002 19.3751C20.4937 19.3751 24.5418 15.327 24.5418 10.3334C24.5418 5.33984 20.4937 1.29175 15.5002 1.29175C10.5066 1.29175 6.4585 5.33984 6.4585 10.3334C6.4585 15.327 10.5066 19.3751 15.5002 19.3751Z"
      stroke="#409EFF"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6044 17.9414L9.0415 29.7085L15.4998 25.8335L21.9582 29.7085L20.3953 17.9285"
      stroke="#409EFF"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
