



















import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {Terminal} from 'xterm-js';
import {FitAddon, WebLinksAddon} from '@/addons';
import {LabWebsocket} from '@/models/labWs';
import {UserLabModule} from '@/store/modules/userLabState';

@Component({
  name: 'Terminal',
  components: {},
})
export default class extends Vue {
  @Ref('terminal') readonly terminalRef!: HTMLDivElement;

  //labID = '';
  notPrintable = [
    'Backspace',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
  ];

  private terminal = new Terminal({
    convertEol: true,
  });
  private fitAddon = new FitAddon();
  private webLinksAddon = new WebLinksAddon();

  private resizeObserver = new ResizeObserver(() => this.onResize());
  private timerResizeSend;
  private errorCache = '';

  get ws() {
    return UserLabModule.ws;
  }

  get connetionLoading() {
    return UserLabModule.connectionLoading;
  }

  get errorMessage() {
    return UserLabModule.connectionError;
  }

  get labDisabled() {
    return UserLabModule.labDisabled;
  }

  @Watch('ws')
  registerTermBind() {
    if (this.ws) {
      this.ws.on('message', this.sessionMessageHandler.bind(this));
    }
  }

  @Watch('errorMessage')
  function() {
    if (this.errorMessage) {
      this.terminal.dispose();
    }
  }

  async mounted() {
    this.InitTerminal();
  }

  created() {
    UserLabModule.SetLabDisableCallback(this.onLabDisable);
    UserLabModule.SetLabEventCallback(this.onLabEvents);
    UserLabModule.SetLabErrorCallback(this.onLabErrors);
    UserLabModule.SetLabTestingCallback(this.onLabTesting);

    window.addEventListener('resize', this.onResize);
  }

  beforeDestroy() {
    this.resizeObserver.disconnect();
    window.removeEventListener('resize', this.onResize);
  }

  async sessionMessageHandler(msg: Blob) {
    const buffer = await msg.arrayBuffer();
    this.terminal.write(new Uint8Array(buffer));
  }

  finishLabHandler(msg: LabWebsocket.IncomingMessages.Finish) {
    /* */
  }

  private InitTerminal() {
    this.onLabDisable(true);
    this.terminal.attachCustomKeyEventHandler(this.customKeyHandler);
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.loadAddon(this.webLinksAddon);
    this.terminal.onData((data) => this.ws?.InteractiveSession(data));

    this.resizeObserver.observe(this.terminalRef);

    this.terminal.open(this.terminalRef);
    this.terminal.focus();

    this.fitAddon.fit();

    UserLabModule.SetTermSize({
      cols: this.terminal.cols,
      rows: this.terminal.rows,
    });
  }

  private customKeyHandler(e: KeyboardEvent): boolean {
    this.$emit('input-terminal');
    return true;
  }

  onResize() {
    const {cols, rows} = this.terminal;

    UserLabModule.SetTermSize({
      cols: this.terminal.cols,
      rows: this.terminal.rows,
    });
    if (this.timerResizeSend) {
      clearTimeout(this.timerResizeSend);
    }
    this.timerResizeSend = setTimeout(() => {
      this.ws && this.ws.SendSize(cols, rows);
    }, 100);

    this.fitAddon.fit();
  }

  public enableStdin(status: boolean) {
    this.terminal.setOption('disableStdin', !status);
    this.terminal.setOption('cursorBlink', status);
    this.terminal.setOption('cursorStyle', status ? 'block' : 'underline');
  }

  private onLabEvents(action) {
    switch (action) {
      case LabWebsocket.ACTIONS.CONNECT_LAB:
        {
          //
        }
        break;
      case LabWebsocket.ACTIONS.START_SESSION:
        {
          this.terminal.clear();
          this.onLabDisable(false);
          this.$emit('start-session');
        }
        break;
      case LabWebsocket.ACTIONS.FINISH:
        {
          this.onLabDisable(true);
        }
        break;
      default: {
        const stdinDisabled = this.terminal.getOption('disableStdin');
        if (stdinDisabled) {
          this.terminal.writeln(action);
        }
      }
    }
  }

  private onLabErrors(e) {
    const stdinDisabled = this.terminal.getOption('disableStdin');
    if (stdinDisabled) {
      try {
        const msg = e.toString();
        if (msg.indexOf('\n') == -1) {
          this.errorCache += msg;
          return;
        }
        this.errorCache += msg;

        this.terminal.writeln(
          '\x1b[1;31m' + this.errorCache.trim() + '\x1b[0m'
        );
        this.errorCache = '';
      } catch (e) {
        /* */
      }
    }
  }

  private onLabTesting() {
    this.terminal.writeln('');
    this.onLabDisable(true);
  }

  private onLabDisable(status: boolean) {
    this.enableStdin(!status);
  }
}
