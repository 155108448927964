<template>
  <lottie
    :options="defaultOptions"
    :height="220"
    :width="180"
    v-on:animCreated="handleAnimation"
    class="lottie"
  />
</template>

<script>
// import Lottie from "vue-lottie";
import animationData from '@/assets/result_best.json';
export default {
  name: 'HelloWorld',
  data() {
    return {
      defaultOptions: {
        animationData: animationData,
        renderer: 'svg',
        loop: true,
        autoplay: true,
      },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },

    stop: function() {
      this.anim.stop();
    },

    play: function() {
      this.anim.play();
    },

    pause: function() {
      this.anim.pause();
    },

    onSpeedChange: function() {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style scoped></style>
