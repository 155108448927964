<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M13.4167 4.08337L9.33337 7.00004L13.4167 9.91671V4.08337Z"
        fill="white"
        fill-opacity="0.85"
        stroke="#909399"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.16671 2.91663H1.75004C1.10571 2.91663 0.583374 3.43896 0.583374 4.08329V9.91663C0.583374 10.561 1.10571 11.0833 1.75004 11.0833H8.16671C8.81104 11.0833 9.33337 10.561 9.33337 9.91663V4.08329C9.33337 3.43896 8.81104 2.91663 8.16671 2.91663Z"
        stroke="#909399"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>

</style>
