






import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({
  name: 'ColoredProgress',
})
export default class extends Vue {
  @Prop()
  public progress!: number;
  @Prop()
  public scoreOnly?: boolean;

  private colors: {color: string; percentage: number}[] = [
    {color: '#DA350B', percentage: 49},
    {color: '#EB8500', percentage: 79},
    {color: '#39933D', percentage: 100},
  ];

  get percentColor() {
    const p = this.progress;
    for (const c of this.colors) {
      if (p <= c.percentage) {
        return c.color;
      }
    }
    return '#FAF873';
  }
}
