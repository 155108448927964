<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 3H21V9" fill="white" fill-opacity="0.45" />
    <path
      d="M15 3H21V9"
      stroke="white"
      stroke-opacity="0.45"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M9 21H3V15" fill="white" fill-opacity="0.45" />
    <path
      d="M9 21H3V15"
      stroke="white"
      stroke-opacity="0.45"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 3L14 10"
      stroke="white"
      stroke-opacity="0.45"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 21L10 14"
      stroke="white"
      stroke-opacity="0.45"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
