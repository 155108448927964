









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'Timer',
  components: {}
})
export default class extends Vue {
  @Prop({ default: () => '' }) readonly time!: string;
  @Prop({ default: () => true }) readonly enabled!: boolean;

  private scopedSeconds = 0;
  private interval = 0;

  @Watch('time')
  private checkTime() {
    let time = this.time;

    if (!time) {
      time = '00:00';
    }

    let [hours, minutes, seconds] = time.split(':').map(Number);

    if (!hours) {
      hours = 0;
    }

    if (!minutes) {
      minutes = 0;
    }

    if (!seconds) {
      seconds = 0;
    }

    this.scopedSeconds = hours * 3600 + minutes * 60 + seconds;
    this.countdown();
  }

  mounted() {
    this.checkTime();
  }

  private doneHandler() {
    window.clearInterval(this.interval);
    this.$emit('done');
  }

  get hours() {
    return Math.floor(this.scopedSeconds / 3600);
  }

  get minutes() {
    return `${Math.floor(
      (this.scopedSeconds - this.hours * 3600) / 60
    )}`.padStart(2, '0');
  }

  get seconds() {
    return `${this.scopedSeconds -
      this.hours * 3600 -
      Number(this.minutes) * 60}`.padStart(2, '0');
  }

  beforeDestroy() {
    window.clearInterval(this.interval);
  }

  countdown() {
    window.clearInterval(this.interval);
    this.interval = window.setInterval(() => {
      if (!this.enabled) {
        return;
      }
      --this.scopedSeconds;
      if (this.scopedSeconds <= 0) {
        this.scopedSeconds = 0;
        this.doneHandler();
      }
    }, 1000);
  }
}
