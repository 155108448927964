





























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Eye from '@/assets/svg/ Eye.vue';
import { AppModule } from '@/store/modules/appState';
import { UsersModule } from '@/store/modules/usersState';
import { IEditUser } from '@/models/users';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'ProfileView',
  components: { Eye },
  data() {
    return {
      ruleForm: {
        pass: '',
        newPass: '',
        checkPass: ''
      },
      rules: {
        pass: [
          {
            validator: (rule: string, value: string, callback: Function) => {
              !value && this.$data.ruleForm['newPass']
                ? callback(new Error('Please input the password.'))
                : callback();
            },
            trigger: 'blur'
          }
        ],
        newPass: [
          {
            validator: (rule: string, value: string, callback: Function) => {
              if (
                (!value && !this.$data.ruleForm['pass']) ||
                value.match(
                  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*().,/?:;])([a-zA-Z0-9!@#$%^&*().,/?:;]+){6,}$/
                )
              ) {
                return callback();
              }

              callback(
                new Error(
                  'Password must be at least 6 characters long, must contain at least one uppercase, one lowercase letter, a number, and a symbol "!@#$%^&*().,/?:;".'
                )
              );
            },
            trigger: 'blur'
          }
        ],
        checkPass: [
          {
            validator: (rule: string, value: string, callback: Function) => {
              value === this.$data.ruleForm['newPass']
                ? callback()
                : callback(new Error('Two inputs don`t match!'));
            },
            trigger: 'blur'
          }
        ]
      },
      userName: AppModule.UserName
    };
  }
})
export default class extends Vue {
  private user: IEditUser = {} as IEditUser;
  private isValid = false;
  private imageData = '';
  private icons = {
    pass: { type: 'password', icon: 'el-input__icon el-icon-view' },
    newPass: { type: 'password', icon: 'el-input__icon el-icon-view' },
    checkPass: { type: 'password', icon: 'el-input__icon el-icon-view' }
  };

  @Watch('ruleForm', { deep: true })
  private validateForm() {
    (this.$refs.ruleForm as ElForm).validate(
      (valid: boolean) => (this.isValid = (!!this.imageData && valid) || valid)
    );
  }

  async created() {
    this.user = await UsersModule.GetCurrentUser();
  }

  public submitForm() {
    if (this.isValid) this.profileUpdate();
  }

  public async profileUpdate() {
    const user: IEditUser = {
      id: this.user.id,
      email: this.user.email,
      userName: this.user.userName
    };

    if (this.imageData) user.avatar = this.imageData;

    if (this.$data.ruleForm['newPass']) {
      user.password = this.$data.ruleForm['newPass'];
      user.oldPassword = this.$data.ruleForm['pass'];
    }

    await UsersModule.EditUser(user);
  }

  public resetForm() {
    (this.$refs.ruleForm as ElForm).resetFields();
  }

  previewImage(event) {
    if (event.target?.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        if (e) {
          this.user.avatar = this.imageData = e.target?.result as string;
          this.validateForm();
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  showPass(item) {
    if (this.icons[item].type == 'text') {
      this.icons[item].type = 'password';
      this.icons[item].icon = 'el-input__icon el-icon-view';
    } else {
      this.icons[item].type = 'text';
      this.icons[item].icon = 'el-input__icon el-icon-lock';
    }
  }
}
