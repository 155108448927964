












































































































































































import { Component, Vue, PropSync } from 'vue-property-decorator';
import ProgressBar from '@/components/progressBar/Index.vue';
import ArrowBack from '@/assets/svg/ArrowBack.vue';
import Award from '@/assets/svg/Award.vue';
import Notepad from '@/assets/svg/Notepad.vue';
import Calendar from '@/assets/svg/Calendar.vue';
import Camera from '@/assets/svg/Camera.vue';
import Play from '@/assets/svg/Play.vue';
import CollapseList from './CollapseList.vue';
import {
  getLabData,
  getLabLogScriptResult,
  getLabLogScripts,
  getLogDataCurrentUser
} from '@/api/labs';
import { ILabResult } from '../../models/labs';
import { decodeASCII } from '@/utils/Lab';

@Component({
  name: 'History',
  components: {
    ProgressBar,
    ArrowBack,
    Award,
    Notepad,
    Calendar,
    Camera,
    Play,
    CollapseList
  },
  filters: {
    date: value => {
      const d = new Date(value);
      const options: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      };
      const optionsT: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric'
      };
      return (
        d.toLocaleDateString('ru-RU', options) +
        ', ' +
        d.toLocaleTimeString('ru-RU', optionsT)
      );
    }
  }
})
export default class extends Vue {
  @PropSync('id') private labID!: string | number;
  private logID;
  private showVideoDialog = false;
  private currentVideoUrl = null;
  private videoLoading = false;
  private playerError = false;
  private dialogTableVisible = false;
  private labData = { name: '' };
  private labResult: ILabResult | null = {
    bestAttempt: false,
    logID: 0,
    totalScore: 0
  };
  private page = 0;
  private total = 0;
  private attemptsLoading = false;
  private attempts = {};
  private bestScore = 101;
  private showLogDialog = false;
  private attemptLog = '';

  async mounted() {
    getLabData(Number(this.labID)).then(({ data }) => {
      this.labData = data;
    });
    this.loadAttemptResult();
  }

  async loadAttemptResult() {
    try {
      const resp = await getLabLogScriptResult({
        labID: Number(this.labID),
        logID: this.logID || undefined
      });
      if (resp?.data) return (this.labResult = resp.data);
    } catch (e) {
      console.error(e);
    }

    throw Error('Failed to get log history!');
  }

  goToLab(labID: number) {
    this.$router.push({ name: 'LabView', params: { id: labID.toString() } });
  }

  openVideoDialog(videoUrl) {
    if (!videoUrl) {
      return;
    }
    this.currentVideoUrl = videoUrl;
    this.showVideoDialog = true;
    this.videoLoading = true;
  }

  closeVideoDialog() {
    this.showVideoDialog = false;
  }

  openAttempts() {
    this.attempts = [];
    this.page = 0;
    this.loadAttempts();

    this.dialogTableVisible = true;
  }

  async loadAttempts() {
    this.attemptsLoading = true;
    this.bestScore = 101;

    try {
      const resp = await getLabLogScripts({
        labID: Number(this.labID),
        page: this.page,
        userID: this.$store.getters['UserID']
      });

      if (resp?.data) {
        this.total = resp.data.totalPages;
        this.attempts = resp.data.logs;
        this.bestScore = resp.data.bestScore;
        this.page = resp.data.page;
      }
    } catch (e) {
      console.error(e);
    }

    this.attemptsLoading = false;
  }

  handleCurrentPageChange(page) {
    this.page = page;
    this.loadAttempts();
  }

  selectAttempt(item) {
    this.dialogTableVisible = false;
    this.logID = item.id;
    this.loadAttemptResult();
  }

  async showLogs() {
    this.showLogDialog = true;
    const { logID } = this.labResult as ILabResult;

    if (logID) {
      const resp = await getLogDataCurrentUser({
        labID: Number(this.labID),
        logID
      });

      if (resp?.data) return (this.attemptLog = decodeASCII(resp.data));
    }

    this.attemptLog = 'Logs for this attempt were not found';
  }

  closeLogDialog() {
    this.showLogDialog = false;
  }
}
