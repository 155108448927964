





















































































































import { Component, Vue } from 'vue-property-decorator';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

import ProgressBar from '@/components/progressBar/Index.vue';
import Terminal from '@/components/terminal/Index.vue';
import { UserLabModule } from '@/store/modules/userLabState';
import LabChallenges from './LabChallenges.vue';
import Clock from '@/assets/svg/Clock.vue';
import Maximize from '@/assets/svg/Maximize.vue';

@Component({
  name: 'LabView',
  components: {
    ProgressBar,
    Terminal,
    LabChallenges,
    Clock,
    Maximize
  }
})
export default class extends Vue {
  private labID = +this.$router.currentRoute.params.id;
  private terminalOn = true;
  private currentPage = 1;
  private labScore = 0;
  private labError = '';
  private showLabWasFinished = false;
  private showLabWasFailed = false;
  private waitTask = false;
  private otherLabStarted = false;
  private otherLabID = -1;
  private otherLabName = '';
  private isInputTerminal = false;
  private isStartSession = false;

  get infoLoading() {
    return UserLabModule.infoLoading;
  }

  get connectionReady() {
    return !UserLabModule.connectionLoading && !UserLabModule.connectionError;
  }

  get fullscreenSupport() {
    return document.fullscreenEnabled || false;
  }

  get lab() {
    return UserLabModule.lab;
  }

  get labDisabled() {
    return UserLabModule.labDisabled;
  }

  get challenge() {
    return UserLabModule.challenge;
  }

  get videoUrl() {
    return this.lab?.videoUrl ?? '';
  }

  created() {
    this.labID = +this.$router.currentRoute.params.id;
    this.showLabWasFinished = false;
    UserLabModule.SetFinishLabCallback(this.finishLab);
    UserLabModule.SetFinishTaskCallback(this.finishTask);
    UserLabModule.SetOtherLabConnectedCallback(this.otherLabConnected);
    UserLabModule.Init(this.labID);
  }

  beforeDestroy() {
    UserLabModule.ClearState();
  }

  fullscreen() {
    const terminal: any = this.$refs.terminal;
    if (!terminal || !terminal.$el) {
      return;
    }
    if (terminal.$el.requestFullscreen) {
      terminal.$el.requestFullscreen();
    } else if (terminal.$el.mozRequestFullScreen) {
      terminal.$el.mozRequestFullScreen();
    } else if (terminal.$el.webkitEnterFullScreen) {
      terminal.$el.webkitEnterFullScreen();
    }
  }

  changeTerminalOn(value) {
    if (!value) {
      return;
    }
    this.terminalOn = !this.terminalOn;
  }

  beforeRouteLeave(to, from, next) {
    UserLabModule.ClearState();
    next();
  }

  finishLab(labID, logID, error) {
    if (error) {
      this.labError = error;
      this.showLabWasFailed = true;
    } else {
      this.$router.push({ name: 'Result', params: { labID: labID } });
    }
  }

  finishTask(labID, taskID, logID, score, failed, error, taskSuccess) {
    if (this.lab) {
      this.lab.percentage = +score;
    }
    this.waitTask = false;
    if (logID) {
      UserLabModule.SetLogID(logID);
    }

    (this.$refs.challenges as LabChallenges).onFinishTask(
      labID,
      taskID,
      score,
      failed,
      taskSuccess
    );
  }

  closeLab() {
    const course = this.lab?.courseID;
    if (course) {
      this.$router.push({
        name: 'CourseView',
        params: { id: course.toString() }
      });
    } else {
      this.$router.push({
        name: 'UserDashboard'
      });
    }
    this.showLabWasFinished = false;
  }

  hideLabError() {
    this.showLabWasFailed = false;
  }

  onWaitTask() {
    this.waitTask = true;
  }

  otherLabConnected(labID: number, startedLabID: number, otherLabName: string) {
    this.otherLabID = startedLabID;
    this.otherLabName = otherLabName;
    this.otherLabStarted = true;
  }

  hideLabStartedInfo() {
    this.otherLabStarted = false;
  }

  goToLab(labID) {
    this.$router.push({ name: 'LabView', params: { id: labID.toString() } });
  }

  doFinishLab(labID) {
    UserLabModule.FinishLabByID(labID);
    this.otherLabStarted = false;
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  onInputTerminal() {
    this.isInputTerminal = true;

    this.$nextTick(() => {
      this.isInputTerminal = false;
    });
  }
}
