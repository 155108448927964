





































































import { Component, Vue, PropSync } from 'vue-property-decorator';
import ProgressBar from '@/components/progressBar/Index.vue';
import CollapseArrow from '@/assets/svg/CollapseArrow.vue';
import Done from '@/assets/svg/Done.vue';
import Task from '@/assets/svg/Task.vue';
import ResultBest from '@/assets/svg/ResultBest.vue';
import ResultGood from '@/assets/svg/ResultGood.vue';
import ResultMiddle from '@/assets/svg/ResultMiddle.vue';
import ResultLow from '@/assets/svg/ResultLow.vue';
import CollapseList from './CollapseList.vue';
import {
  getLabData,
  getLabLogScriptResult,
  getLogDataCurrentUser
} from '@/api/labs';
import { ILabResult } from '../../models/labs';
import { decodeASCII } from '@/utils/Lab';

@Component({
  name: 'LasResult',
  components: {
    ProgressBar,
    CollapseArrow,
    Done,
    Task,
    CollapseList,
    ResultBest,
    ResultGood,
    ResultMiddle,
    ResultLow
  },
  filters: {
    date: value => {
      const d = new Date(value);
      const options: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      };
      const optionsT: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric'
      };
      return (
        d.toLocaleDateString('ru-RU', options) +
        ', ' +
        d.toLocaleTimeString('ru-RU', optionsT)
      );
    }
  }
})
export default class extends Vue {
  @PropSync('id')
  private labID;
  @PropSync('log')
  private logID;

  private labData = { name: '', courseID: 0 };
  private labResult: ILabResult | null = {
    bestAttempt: false,
    logID: 0,
    totalScore: 0
  };
  private labDataLoading = false;

  private showLogDialog = false;
  private attemptLog = '';
  private quote = {
    text: '',
    author: ''
  };

  private quotes = [
    {
      text:
        'When we strive to become better than we are, everything around us becomes better too.',
      author: 'Paulo Coelho'
    },
    {
      text:
        'Success seems to be connected with action. Successful people keep moving. They make mistakes, but they don’t quit.',
      author: 'Conrad Hilton'
    },
    {
      text: 'It does not matter how slowly you go, so long as you do not stop.',
      author: 'Confucius'
    },
    {
      text:
        'If something is wrong, fix it now. But train yourself not to worry, worry fixes nothing.',
      author: 'Ernest Hemingway'
    },
    {
      text:
        'Much of the stress that people feel doesn`t come from having too much to do. It comes from not finishing what they started.',
      author: 'David Allen'
    },
    {
      text:
        'Do not be embarrassed by your failures, learn from them and start again.',
      author: 'Richard Branson'
    },
    {
      text:
        'Show me a person who has never made a mistake and I`ll show you someone who has never achieved much.',
      author: 'Joan Collins'
    },
    {
      text: 'Success is the sum of small efforts repeated day in and day out.',
      author: 'Robert Collier'
    },
    {
      text: 'I have not failed. I’ve just found 10,000 ways that won’t work.',
      author: 'Thomas A. Edison'
    },
    {
      text:
        'Success is a state of mind. If you want success, start thinking of yourself as a success.',
      author: 'Joyce Brothers'
    },
    {
      text:
        'Good work has a snowball effect, it keeps leading to more good work.',
      author: 'Richa Chadha'
    },
    {
      text: 'There is a kind of victory in good work, no matter how humble.',
      author: 'Jack Kemp'
    }
  ];

  created() {
    this.quote = this.random();
  }

  random() {
    return this.quotes[Math.floor(Math.random() * this.quotes.length)];
  }

  mounted() {
    if (!this.labID) {
      this.$router.push({ name: 'UserDashboard' });
    }
    this.labDataLoading = true;
    getLabData(this.labID)
      .then(({ data }) => {
        this.labData = data;
      })
      .then(() =>
        getLabLogScriptResult({
          labID: Number(this.labID),
          logID: Number(this.logID) || -1
        })
      )
      .then(({ data }) => {
        this.labResult = data;
      })
      .finally(() => {
        this.labDataLoading = false;
      });
  }

  backToCourse() {
    if (this.labData?.courseID) {
      this.$router.push({
        name: 'CourseView',
        params: { id: String(this.labData?.courseID) }
      });
    }
  }

  async showLogs() {
    this.showLogDialog = true;
    const { logID } = this.labResult as ILabResult;

    if (logID) {
      const resp = await getLogDataCurrentUser({ labID: this.labID, logID });
      if (resp?.data) return (this.attemptLog = decodeASCII(resp.data));
    }

    this.attemptLog = 'Logs for this attempt were not found';
  }

  closeLogDialog() {
    this.showLogDialog = false;
  }
}
