<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16659 1.16663H3.49992C3.1905 1.16663 2.89375 1.28954 2.67496 1.50833C2.45617 1.72713 2.33325 2.02387 2.33325 2.33329V11.6666C2.33325 11.976 2.45617 12.2728 2.67496 12.4916C2.89375 12.7104 3.1905 12.8333 3.49992 12.8333H10.4999C10.8093 12.8333 11.1061 12.7104 11.3249 12.4916C11.5437 12.2728 11.6666 11.976 11.6666 11.6666V4.66663L8.16659 1.16663Z"
      stroke="#909399"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.16675 1.16663V4.66663H11.6667"
      stroke="#909399"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33342 7.58331H4.66675"
      stroke="#909399"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33342 9.91669H4.66675"
      stroke="#909399"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83342 5.25H5.25008H4.66675"
      stroke="#909399"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
