




















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'ProgressRing'
})
export default class extends Vue {
  @Prop() private radius!: number;
  @Prop() private stroke!: number;
  @Prop() private percentage!: number;

  private normalizedRadius = this.radius - this.stroke * 2;
  private circumference = this.normalizedRadius * 2 * Math.PI;
  private strokeDashoffset = this.circumference - this.percentage / 100 * this.circumference;
}
