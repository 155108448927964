import { GET_LAB_SOCKET_URL } from '@/consts';
import { LabWebsocket } from '@/models/labWs';
import { EventEmmiter } from '@/utils/EventEmmiter';
import { ReconnectingWebSocket } from './reconnecting-websocket';
import { UserLabModule } from '@/store/modules/userLabState';

type ErrorHandler = (err: LabWebsocket.IncomingMessages.ErrorMessage) => void;
type MessageHandler<T extends LabWebsocket.IncomingMessages.Message> = (
  payload: T
) => void;

interface LabWebsocketHandlers {
  [LabWebsocket.ACTIONS.CONNECT_LAB]: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.ConnectLab>
  ];
  [LabWebsocket.ACTIONS.START_SESSION]: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.StartSession>
  ];
  [LabWebsocket.ACTIONS.SESSION]?: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.Session>
  ];
  [LabWebsocket.ACTIONS.FINISH]: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.Finish>
  ];
  [LabWebsocket.ACTIONS.FINISH_TASK]: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.FinishTask>
  ];
  [LabWebsocket.ACTIONS.EVENT]: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.EventLog>
  ];
  [LabWebsocket.ACTIONS.ERROR]: [
    MessageHandler<LabWebsocket.IncomingMessages.ErrorLog>
  ];
  [LabWebsocket.ACTIONS.TESTING]: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.Testing>
  ];
  [LabWebsocket.ACTIONS.OTHER_LAB]: [
    ErrorHandler,
    MessageHandler<LabWebsocket.IncomingMessages.OtherLab>
  ];
}

export class LabWebsocketConnection extends EventEmmiter<'close' | 'message'> {
  private ws!: ReconnectingWebSocket;
  private actions: LabWebsocketHandlers;

  static async getInstance(labID: number, handlers: LabWebsocketHandlers) {
    const obj = new this(handlers);
    await obj.Init(labID);
    return obj;
  }

  protected constructor(handlers: LabWebsocketHandlers) {
    super();
    this.actions = { ...handlers };
  }

  get termSize() {
    return UserLabModule.termSize;
  }

  public async Init(labID: number) {
    await new Promise((res, rej) => {
      const token = localStorage.getItem('ds-token');
      if (!token) {
        return rej('No token found for websocket');
      }
      this.ws = new ReconnectingWebSocket(GET_LAB_SOCKET_URL(token, labID));
      this.ws.reconnectInterval = 2000;
      this.ws.onclose = () => {
        this.emit('close');
        UserLabModule.SetDisconnect();
        this.ws.removeEventListener('message', this.messageHandler.bind(this));
      };
      this.ws.onerror = rej;
      this.ws.onopen = r => {
        this.ws.addEventListener('message', this.messageHandler.bind(this));
        this.termSize &&
          this.ConnectLab(this.termSize.cols, this.termSize.rows);
        res(r);
      };
    });
    return this;
  }

  public ConnectLab(cols, rows) {
    this.message({ action: LabWebsocket.ACTIONS.CONNECT_LAB, cols, rows });
  }

  public StartSession() {
    this.message({ action: LabWebsocket.ACTIONS.START_SESSION });
  }

  public InteractiveSession(message: string) {
    this.message({
      action: LabWebsocket.ACTIONS.SESSION,
      message
    });
  }

  public InteractiveSessionListener(ev: any) {
    this.message({
      action: LabWebsocket.ACTIONS.SESSION,
      message: ev.key
    });
  }

  public FinishLab(taskID: string, logID: number, labID?: number) {
    this.message({
      action: LabWebsocket.ACTIONS.FINISH,
      taskID,
      logID,
      otherLabID: labID
    });
  }

  public FinishTask(taskID: string, nextTaskID: string, logID?: number) {
    this.message({
      action: LabWebsocket.ACTIONS.FINISH_TASK,
      taskID: taskID,
      currentTaskId: nextTaskID,
      logID: logID
    });
  }

  public SendSize(cols, rows) {
    this.message({ action: LabWebsocket.ACTIONS.RESIZE, cols, rows });
  }

  private message(msg: LabWebsocket.OutgoingMessages.Message) {
    this.ws.send(JSON.stringify(msg));
  }

  private messageHandler(msg: MessageEvent) {
    // At terminal session incoming messages are binary data
    // So we need to check and little validate that
    const data: LabWebsocket.IncomingMessages.Message = (() => {
      try {
        return JSON.parse(msg.data);
      } catch (e) {
        if (msg.data instanceof Blob) {
          return msg.data;
        }
        throw new Error('Invalid format of message');
      }
    })();
    const [error, result] = (() => {
      if (data instanceof Blob) {
        const [err, fn] = this.actions.interactive_session ?? [];
        return [
          err ??
            (() => {
              /** */
            }),
          (data: Blob) => {
            if (fn) {
              fn(data);
            }
            this.emit('message', data);
          }
        ];
      }

      if (data.action && data.action in this.actions) {
        return this.actions[data.action];
      }
      return [];
    })();
    if ('error' in data && data.error) {
      error && error(data);
    } else {
      result && result(data);
    }
  }

  public destroy() {
    this.ws.removeEventListener('message', this.messageHandler.bind(this));
    this.ws.close();
  }
}
