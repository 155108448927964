







































































































































import {Component, Vue} from 'vue-property-decorator';
import {UserDashboardModule} from '../../store/modules/userDashboardState';
import {ICourseInfo} from '@/models/users';
import {Difficulty} from '@/models/users.enums';
import ProgressBar from '@/components/progressBar/Index.vue';
import ProgressRing from '@/components/progressRing/Index.vue';
import ArrowBack from '@/assets/svg/ArrowBack.vue';
import Clock from '@/assets/svg/Clock.vue';
import Camera from '@/assets/svg/Camera.vue';
import Play from '@/assets/svg/Play.vue';
import Easy from '@/assets/svg/Easy.vue';
import Medium from '@/assets/svg/Medium.vue';
import Hard from '@/assets/svg/Hard.vue';
import ColoredProgress from '@/components/colored-progress/Index.vue';

@Component({
  name: 'CourseView',
  components: {
    ProgressBar,
    ProgressRing,
    ArrowBack,
    Clock,
    Camera,
    Play,
    Easy,
    Medium,
    Hard,
    ColoredProgress,
  },
})
export default class extends Vue {
  readonly Difficulty = Difficulty;
  private course: ICourseInfo | null = null;
  private courseID = '0';
  private courseLoading = true;
  private showVideoDialog = false;
  private currentVideoUrl = null;
  private videoLoading = false;
  private playerError = false;

  get currentCourse() {
    return UserDashboardModule.currentCourse;
  }

  isEmpty(obj) {
    return !Object.keys(obj).length;
  }

  async mounted() {
    this.courseID = this.$router.currentRoute.params.id;
    try {
      await UserDashboardModule.GetCourseByID(+this.courseID);
      if (!UserDashboardModule.currentCourse) {
        throw new Error('No course found');
      }
      this.course = UserDashboardModule.currentCourse;
    } catch (e) {
      this.$router.push({name: 'UserDashboard'});
    } finally {
      this.courseLoading = false;
    }
  }

  goToLink(labID: number) {
    this.$router.push({name: 'LabView', params: {id: labID.toString()}});
  }

  goToStatistics(statID: number) {
    this.$router.push({name: 'History', params: {id: statID.toString()}});
  }

  openVideoDialog(videoUrl) {
    if (!videoUrl) {
      return;
    }
    this.currentVideoUrl = videoUrl;
    this.showVideoDialog = true;
  }

  closeVideoDialog() {
    this.showVideoDialog = false;
  }
}
