


















































import {Component, Vue} from 'vue-property-decorator';
import {UserDashboardModule} from '../../store/modules/userDashboardState';
import {ICourse} from '@/models/users';
import ProgressBar from '@/components/progressBar/Index.vue';
//import Breadcrumbs from '@/components/breadcrumbs/Index.vue';
import CourseCard from '@/components/course-card/Index.vue';
import Logs from '@/components/logs/Index.vue';
import {DialogModule} from '@/store/modules/dialogState';

@Component({
  name: 'Dashboard',
  components: {
    ProgressBar,
    //Breadcrumbs,
    CourseCard,
    Logs,
  },
})
export default class extends Vue {
  private courses: ICourse[] | null = null;
  private activeTab = '0';

  private coursesLoading = true;

  get showDialog() {
    return DialogModule.showEdit;
  }

  get selectedCourse() {
    return UserDashboardModule.selectedCourse;
  }

  get labsForUser() {
    return UserDashboardModule.labsForUser;
  }

  get labLogForUser() {
    return UserDashboardModule.labLogForUser;
  }

  mounted() {
    UserDashboardModule.GetCoursesForUser()
      .then(() => {
        this.courses = UserDashboardModule.coursesForUser;
      })
      .finally(() => {
        this.coursesLoading = false;
      });
  }

  private closeDialog() {
    DialogModule.closeCreate();
    UserDashboardModule.SetSelectedCourse(null);
    this.activeTab = '0';
  }
}
